<template>
  <div>
    <vx-card>
      <div class="flex flex-wrap justify-between items-center">
        <!-- ITEMS PER PAGE -->
        <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2">{{ GeneratePageSizeButton() }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                @click="chargesGridApi.paginationSetPageSize(20)"
              >
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item
                @click="chargesGridApi.paginationSetPageSize(50)"
              >
                <span>50</span>
              </vs-dropdown-item>
              <vs-dropdown-item
                @click="chargesGridApi.paginationSetPageSize(100)"
              >
                <span>100</span>
              </vs-dropdown-item>
              <vs-dropdown-item
                @click="chargesGridApi.paginationSetPageSize(150)"
              >
                <span>150</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <div
          class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
        >
          <vs-input
            class="mb-4 md:mb-0 mr-4"
            v-model="chargesSearchQuery"
            @input="updateChargesSearchQuery"
            placeholder="Buscar..."
          />
          <vs-button
            class="mb-4 md:mb-0 mr-4"
            @click="chargesGridApi.exportDataAsExcel({rowHeight: 20})"
            >Exportar Excel</vs-button
          >
          <vx-tooltip text="Refrescar datos">
            <vs-button
              class="mb-4 md:mb-0 mr-4"
              radius
              color="primary"
              type="border"
              icon-pack="feather"
              icon="icon-refresh-cw"
              @click="updateData"
            ></vs-button>
          </vx-tooltip>
          <vx-tooltip color="dark" text="Limpiar filtros">
            <vs-button
              radius
              color="danger"
              type="border"
              icon-pack="feather"
              icon="icon-x"
              @click="resetFilters"
            ></vs-button>
          </vx-tooltip>
        </div>
      </div>
      <ag-grid-vue
        ref="agGridTable"
        :gridOptions="chargesGridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columns"
        :defaultColDef="chargesDefaultColDef"
        :rowData="data"
        rowSelection="multiple"
        rowMultiSelectWithClick="true"
        :enableRangeSelection="true"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="true"
        :getContextMenuItems="getChargesContextMenuItems"
        :pagination="true"
        :paginationPageSize="chargesPaginationPageSize"
        :suppressPaginationPanel="true"
        tooltipShowDelay="0"
        @selection-changed="onSelectionChanged"
        :frameworkComponents="frameworkComponents"
      >
      </ag-grid-vue>
      <vs-pagination
        :total="chargesTotalPages"
        :max="chargesMaxPageNumbers"
        v-model="chargesCurrentPage"
      />
    </vx-card>
  </div>
</template>
<script>
import { LicenseManager } from 'ag-grid-enterprise'
LicenseManager.setLicenseKey(
  'CompanyName=Servoy B.V.,LicensedApplication=Servoy,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=7,LicensedProductionInstancesCount=200,AssetReference=AG-010463,ExpiryDate=11_October_2021_[v2]_MTYzMzkwNjgwMDAwMA==4c6752fe4cb2066ab1f0e9c572bc7491'
)

import { AgGridVue } from 'ag-grid-vue'
import _spanishLocaleText from '@/constants/spanishLocaleText.js'

export default {
  components: {
    AgGridVue
  },
  props: {
    data: {
      type: [],
      required: true
    },
    loadingData: {
      type: Boolean,
      required: true
    },
    columns: {
      type: [],
      required: true
    },
    frameworkComponents: Object
  },
  data() {
    return {
      chargesSearchQuery: '',
      chargesGridApi: null,
      chargesMaxPageNumbers: 7,
      chargesGridOptions: {
        localeText: _spanishLocaleText.getText()
      },
      chargesDefaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
        headerHeight: 10
      },
      countSelected: 0,
      selectedRows: []
    }
  },
  watch: {
    '$store.state.windowWidth'(val) {
      if (val <= 576) {
        this.chargesMaxPageNumbers = 4
        this.chargesGridOptions.columnApi.setColumnPinned('email', null)
      } else {
        this.chargesGridOptions.columnApi.setColumnPinned('email', 'left')
      }
    },
    loadingData(newLoadingData) {
      if (newLoadingData) return this.chargesGridApi.showLoadingOverlay()
      this.chargesGridApi.hideOverlay()
    }
  },
  computed: {
    // Charges table computed
    chargesPaginationPageSize() {
      if (this.chargesGridApi)
        return this.chargesGridApi.paginationGetPageSize()
      else return 100
    },
    chargesTotalPages() {
      if (this.chargesGridApi)
        return this.chargesGridApi.paginationGetTotalPages()
      else return 0
    },
    chargesCurrentPage: {
      get() {
        if (this.chargesGridApi)
          return this.chargesGridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set(val) {
        this.chargesGridApi.paginationGoToPage(val - 1)
      }
    }
  },
  methods: {
    resetFilters () {
      this.chargesGridApi.setFilterModel(null)
      this.chargesGridApi.setSortModel(null)
    },
    onSelectionChanged () {
      const selectedRows = this.chargesGridApi.getSelectedRows()
      this.countSelected = selectedRows.length
      this.selectedRows = selectedRows
    },
    getChargesContextMenuItems() {
      const selectedItems = this.chargesGridApi.getSelectedNodes()
      const thereAreSelectedItems =
        Array.isArray(selectedItems) && selectedItems.length
      return [
        'copy',
        'copyWithHeaders',
        {
          name: 'Exportar seleccionados',
          icon: '<span class="ag-icon ag-icon-save" unselectable="on"></span>',
          disabled: !thereAreSelectedItems,
          subMenu: [
            {
              name: 'CSV (.csv)',
              action: () => {
                this.chargesGridApi.exportDataAsCsv({
                  onlySelected: true,
                  columnSeparator: ';'
                })
              }
            },
            {
              name: 'Excel (.xlsx)',
              action: () => {
                this.chargesGridApi.exportDataAsExcel({ onlySelected: true })
              }
            }
          ]
        }
      ]
    },
    updateChargesSearchQuery(val) {
      this.chargesGridApi.setQuickFilter(val)
    },
    updateData() {
      this.$emit('updateData')
    },
    GeneratePageSizeButton() {
      if (this.loadingData) return '...'

      const firstNumberCurrentPage =
        this.chargesCurrentPage * this.chargesPaginationPageSize -
        (this.chargesPaginationPageSize - 1)

      const LastNumberCurrentPage =
        this.data.length -
          this.chargesCurrentPage * this.chargesPaginationPageSize >
        0
          ? this.chargesCurrentPage * this.chargesPaginationPageSize
          : this.data.length

      const total = this.data.length

      return `${firstNumberCurrentPage} - ${LastNumberCurrentPage} of ${total}`
    }
  },
  beforeMount() {},
  mounted() {
    this.chargesGridApi = this.chargesGridOptions.api
  }
}
</script>
